<template>
  <div>
    <p>{{ $t('onboarding.identificationData.desc') }}</p>

    <v-form
      v-model="isFormValid"
      @submit.prevent="submit"
    >
      <!-- BIRTHDATE -->
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="data.identificationData.birthDay"
            :rules="rules.birthDay"
            :label="`${$t('onboarding.identificationData.fields.birthDay')} *`"
            prepend-inner-icon="mdi-calendar"
            outlined
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          ref="picker"
          v-model="datePicker"
          :max="new Date().toISOString().substr(0, 10)"
          min="1920-01-01"
          @change="save"
        ></v-date-picker>
      </v-menu>

      <!-- CITY -->
      <v-text-field
        id="city"
        type="text"
        :rules="rules.city"
        v-model="data.identificationData.birthPlace"
        autocomplete="birthcity"
        required
        :label="`${$t('onboarding.identificationData.fields.birthPlace')} *`"
        outlined
        validate-on-blur
        ref="city"
        @input="checkValidation('city')"
      ></v-text-field>

      <!-- COUNTRY BIRTH -->
      <country-select
        :items="allCountries"
        v-model="data.identificationData.countryBirth"
        :rules="rules.required"
        :label="`${$t('onboarding.identificationData.fields.countryBirth')} *`"
        :default-country="defaultCountry"
      >
      </country-select>

      <!-- COUNTRY PRIMARY CITIZEN -->
      <country-select
        :items="ewrCountries"
        v-model="data.identificationData.countryPrimCitizen"
        :rules="rules.required"
        :label="`${$t('onboarding.identificationData.fields.countryPrimCitizen')} *`"
        :default-country="defaultCountry"
      >
      </country-select>

      <!-- NO US CITIZENSHIP -->
      <v-switch
        v-model="data.identificationData.isUsCitizen"
        :true-value="isUsCitizen.true"
        :false-value="isUsCitizen.false"
        :rules="rules.isUsCitizen"
        :label="`${$t('onboarding.identificationData.fields.isUsCitizen')} *`"
      ></v-switch>

      <!-- NO PEP -->
      <div class="d-flex align-center">
        <v-switch
          v-model="data.identificationData.isPoliticallyExposed"
          :rules="rules.isPoliticallyExposed"
          :label="`${$t('onboarding.identificationData.fields.isPoliticallyExposed')} *`"
        ></v-switch>
        <v-tooltip top color="backgroundLight" :max-width="300">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              color="backgroundLight"
              class="info-chip ml-2"
              small
            >i</v-chip>
          </template>
          <span class="tooltip-message">{{ $t('onboarding.identificationData.tooltipMessage') }}</span>
        </v-tooltip>
      </div>

      <br>
    </v-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import clone from '@/utils/clone';
import {
  city, isUsCitizen, isPoliticallyExposed, required, birthDay,
} from '@/utils/validators';
import CountrySelect from '@/components/Shared/InputFields/CountrySelect.vue';

export default {
  name: 'IdentificationData',
  components: {
    CountrySelect,
  },
  data() {
    return {
      data: clone(this.$store.state.user.data),
      isUsCitizen: {
        true: '0',
        false: '1',
      },
      menu: false,
      rules: {
        city,
        isUsCitizen,
        isPoliticallyExposed,
        required,
        birthDay,
      },
      datePicker: null,
    };
  },
  computed: {
    ...mapGetters('onboarding', [
      'allCountries',
      'ewrCountries',
      'defaultCountry',
      'editMode',
    ]),
    ...mapFields('onboarding', [
      'isFormValid',
    ]),
  },
  /* eslint-disable no-return-assign */
  watch: {
    menu(val) {
      val && setTimeout(() => (
        this.$refs.picker.activePicker = 'YEAR'
      ));
    },
    datePicker(val) {
      const [year, month, day] = val.split('-');
      this.data.identificationData.birthDay = `${day}.${month}.${year}`;
    },
  },
  methods: {
    ...mapActions('user', [
      'updateUser',
      'setSubStage',
      'updateOnboarding',
    ]),
    checkValidation(refName) {
      if (!this.isFormValid) {
        this.$refs[refName].validate();
      }
    },
    async submit() {
      const [day, month, year] = this.data.identificationData.birthDay.split('.');
      const input = {
        investor: {
          personal: {
            identificationData: {
              ...this.data.identificationData,
              birthDay: `${year}-${month}-${day}`,
            },
          },
        },
        mainStage: 'personal',
        subStage: 'identificationData',
      };
      try {
        await this.updateOnboarding({
          input,
          newData: this.data,
        });
        if (this.editMode) {
          this.$store.dispatch('onboarding/editMode', null);
          this.$router.push({
            name: 'Onboarding.Personal',
            params: {
              step: 'Overview',
            },
          });
        } else {
          this.setSubStage('taxinformation');
        }
      } catch (error) {
        this.$notify.error(error.message);
      }
    },
    save(date) {
      this.$refs.menu.save(date);
    },
  },
};
</script>

<style lang="scss">
.info-chip {
  padding-left: 7px !important;
  padding-right: 7px !important;
  height: 16px !important;
}
</style>
